import {
	chakra,
	useBreakpointValue,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Pagination, Autoplay, A11y } from "swiper";
import {
	Swiper,
	SwiperSlide,
	SwiperProps,
	SwiperSlideProps,
	useSwiper,
} from "swiper/react";

// The design requests that on mobile there is no autoplay. We can use chakra's useBreakpointValue to detect changes in breakpoints,
// but Swiper doesn't respond to prop changes after initial load. so instead of dynamically updating the autoplay prop,
// we have to use the swiper instance via useSwiper, which works when the hook is used in a child of <Swiper>.
const ResponsiveSwiperAutoplay: React.FC = () => {
	const swiper = useSwiper();

	const [prevAutoSetting, setAutoSetting] = useState(true);
	const enableAutoplay = useBreakpointValue(
		{
			base: false,
			md: true,
		},
		{
			fallback: "md",
		},
	);

	useEffect(() => {
		// It can apparently be undefined, at least in testing.
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (swiper.autoplay && prevAutoSetting !== enableAutoplay) {
			enableAutoplay ? swiper.autoplay.start() : swiper.autoplay.stop();
			setAutoSetting(Boolean(enableAutoplay));
		}
	}, [enableAutoplay, prevAutoSetting, swiper.autoplay]);

	return null;
};

export const Carousel: React.FC<SwiperProps> = ({ children, ...props }) => {
	const styles = useMultiStyleConfig("Carousel");

	return (
		<chakra.div sx={styles.wrapper}>
			<Swiper
				spaceBetween={24}
				slidesPerView="auto"
				centeredSlides={true}
				loop={true}
				loopedSlides={6}
				grabCursor={true}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination, Autoplay, A11y]}
				{...props}
			>
				<ResponsiveSwiperAutoplay />
				{children}
			</Swiper>
		</chakra.div>
	);
};

export const CarouselItem: React.FC<SwiperSlideProps> = ({
	children,
	...rest
}) => {
	return <SwiperSlide {...rest}>{children}</SwiperSlide>;
};

// Without this, Swiper will not work.
CarouselItem.displayName = "SwiperSlide";

// 🔬 TBD: Please evaluate
