import {
	chakra,
	useBreakpointValue,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { Carousel, CarouselItem } from "src/components/Carousel/Carousel";
import { Product } from "src/components/Product/Product";
import {
	StructuredText,
	StructuredTextData,
	isStructuredText,
} from "src/components/StructuredText/StructuredText";
import { useGetProductsFromStructuredText } from "./utils";

type ProductComparisonProps = {
	introAlignment: string;
	structuredText: StructuredTextData;
};

export const ProductComparison: React.FC<ProductComparisonProps> = ({
	introAlignment,
	structuredText,
}) => {
	const styles = useMultiStyleConfig("ProductComparison", { introAlignment });

	const { intro, products } =
		useGetProductsFromStructuredText(structuredText);

	const isMobile = useBreakpointValue(
		{
			base: true,
			lg: false,
		},
		{ fallback: "base" },
	);

	const isCarousel = products && (products.length > 3 || isMobile);

	if (isCarousel) {
		return (
			<chakra.div __css={styles.carouselWrapper}>
				<chakra.div __css={styles.carouselIntro}>
					{intro && <StructuredText data={intro} />}
				</chakra.div>

				<Carousel
					loop={false}
					autoplay={{
						delay: 8000,
						disableOnInteraction: true,
					}}
				>
					{products.map(
						({
							id,
							icon,
							title,
							headerAlignment,
							startingPrice,
							priceDescription,
							description,
						}) => {
							return (
								<CarouselItem key={id}>
									<Product
										icon={icon}
										title={title}
										headerAlignment={headerAlignment}
										startingPrice={startingPrice ?? 0}
										priceDescription={priceDescription}
										description={
											isStructuredText(description)
												? description
												: undefined
										}
										data-structured-text-block="product"
									/>
								</CarouselItem>
							);
						},
					)}
				</Carousel>
			</chakra.div>
		);
	}

	return (
		<chakra.div __css={styles.wrapper}>
			{intro && (
				<chakra.div __css={styles.intro}>
					<StructuredText data={intro} />
				</chakra.div>
			)}
			<chakra.div __css={styles.items}>
				{products?.map(
					({
						id,
						icon,
						title,
						headerAlignment,
						startingPrice,
						priceDescription,
						description,
					}) => {
						return (
							<Product
								key={id}
								icon={icon}
								title={title}
								headerAlignment={headerAlignment}
								startingPrice={startingPrice ?? 0}
								priceDescription={priceDescription}
								description={
									isStructuredText(description)
										? description
										: undefined
								}
								data-structured-text-block="product"
							/>
						);
					},
				)}
			</chakra.div>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
